
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import AddBoxIcon from '@material-ui/icons/AddBox';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from "@material-ui/icons/Home";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React from "react";
export default [
  {
    name: `Profilo`,
    route: `/profilo`,
    icon: <AccountBoxIcon />,
    type: `USR`,
  },
  {
    name: `Casa`,
    route: `/home`,
    icon: <HomeIcon />,
    type: `USR`,
  },
  {
    name: `Dashboard`,
    route: `/admin/dashbord`,
    icon: <HomeIcon />,
    type: `ADM`,
  },
  {
    name: `Inserisci Corso`,
    route: `/admin/inserisci-corso`,
    icon: <AddBoxIcon />,
    type: `ADM`,
  },
  {
    name: `Corsi Disponibili`,
    route: `/admin/corsi`,
    icon: <CastForEducationIcon />,
    type: `ADM`,
  },
  {
    name: `Studenti iscritti`,
    route: `/admin/studenti-iscritti`,
    icon: <GroupIcon />,
    type: `ADM`,
  },

  {
    name: `Aggiungi Corsista`,
    route: `/admin/aggiungi-corsista`,
    icon: <GroupAddIcon />,
    type: `ADM`,
  },
  // {
  //   name: `Users in AE`,
  //   route: `/admin/list-users`,
  //   icon: <SupervisorAccountIcon />,
  //   type: `ADM`,
  // },
 

];
