import React, { useContext, useState, useEffect } from "react";
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import Context from '../Context'
import { useHistory, useParams, withRouter } from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from "../components/MainTheme.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Divider } from '@material-ui/core';
import { Progress, Popconfirm } from 'antd';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'antd';

function TakeTest(props) {

    const { course_id } = useParams()
    const { section_id } = useParams()

    const { user } = useContext(Context)

    const history = useHistory();
    const classes = useStyles();
    const [next, setNext] = useState(false);
    const [previous, setPrevious] = useState(false);
    const [review, setReview] = useState(false);
    //const [courseTitle, setCourseTitle] = useState([]);
    const [testTitle, setTestTitle] = useState(null);
    const [index, setIndex] = useState(0);
    const [stepIndex, setStepIndex] = useState(0);
    const [answers, setAnswer] = useState([]);
    const [questions, setQuestion] = useState([]);
    const [compareTime, setCpmareTime] = useState([]);
    const [total_score, setTotalScore] = useState([]);
    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);

    const [open, setOpen] = useState(false);

    const [seconds, setSeconds] = useState('59')
    const [minutes, setMinutes] = useState('0')

    const [user_answer, setUser_Answer] = useState([])
    const [userAnswers, setUserAnswers] = useState([]);
    const [saveUserAnswers, setSaveUserAnswers] = useState([]);
    const [percent, setPercent] = useState(0);
    const [step, setStep] = useState(0);

   // const [answerScore, setScoreAnswers] = useState([])
   // const [testScore, setTestScore] = useState('')

    const gridStyle = {
        display: 'flex',
        justifyContent: 'space-between'
    }
    const answerStyle = {
        backgroundColor: '#f3f3f3', display: 'flex', alignItems: 'center',
        marginBottom: '20px', borderRadius: '12px',
        padding: '0px 15px 0 0', width: 'fit-content'
    }


    function updateTime() {
        if (minutes === 0 && seconds === 0) {
            submit()
        }
        else {
            if (seconds === 0) {
                setMinutes(minutes => minutes - 1);
                setSeconds(59);
            } else {
                setSeconds(seconds => seconds - 1);
            }
        }
    }


    useEffect(() => {
        const token = setTimeout(updateTime, 1000)

        return function cleanUp() {
            clearTimeout(token);
        }
    })

    useEffect(() => {
        getData()
        addUserTestTime()
        window.addEventListener("popstate", () => {
            history.go(1);
        });

    }, [])

    useEffect(() => {

        if (compareTime.length > 0) {

            let current_date = new Date().getTime()
            let compare = ((current_date - compareTime[0]) / 1000) / 60

            let compare_minutes = Math.floor(compare)
            let compare_seconds = Math.floor(Math.abs((compare - Math.floor(compare)) * 60))

            if (compare_minutes > minutes) {
                submit()
            } else if (compare_minutes === 0 && compare_seconds > 5) {
                setSeconds((59 - compare_seconds))
            } else if (minutes === compare_minutes) {
                setMinutes(0)
                setSeconds(59 - compare_seconds)
            }
            else if (compare_minutes !== 0) {
                setMinutes(minutes - compare_minutes)
                setSeconds(59 - compare_seconds)

            }

        }
    }, [compareTime])


    async function addUserTestTime() {

        let current_date = new Date().getTime()
        const formData = new FormData();
        formData.append('course_id', course_id)
        formData.append('section_id', section_id)
        formData.append('start_test_at', current_date)

        await fetch("/back-end/public/api/addUserTestTime", {
            method: "POST",
            body: formData
        })
    }

    async function getCompareTime(json) {
        await fetch(`/back-end/public/api/getUserTestTimeCompare/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => setCpmareTime(json)
            )
    }
    async function getData() {

        
        // await fetch(`/back-end/public/api/courseTitle/${course_id}`).then(
        //     response => response.json()).then(
        //         json => {
        //             if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
        //             else setCourseTitle(json)
        //         }
        //     )

        if(section_id === '0'){

            await fetch(`/back-end/public/api/getTestTitleFinalUser/${course_id}`)
            .then((response) => response.json())
            .then(json =>{ 
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else if(json.title)
            
              setTestTitle(json.title)}
            )

            await fetch(`/back-end/public/api/getUserTestTimeFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else {
                            setMinutes(json - 1)
                            getCompareTime(json)
                        }
                    }
                )
    
            await fetch(`/back-end/public/api/getPublishedQuestionFinal/${course_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setQuestion(json)
                    }
                )
                await fetch(`/back-end/public/api/getAnswerFinal/${course_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setAnswer(json)
                        }
                    )
                    await fetch(`/back-end/public/api/getTotalScoreQuestionFinal/${course_id}`).then(
                        response => response.json()).then(
                            json => {
                                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                                else setTotalScore(json)
                            }
                        )
        
        }
           else {
          
            await fetch(`/back-end/public/api/getTestTitleUser/${course_id}/${section_id}`)
            .then((response) => response.json())
            .then(json =>{ 
                if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                else if(json.title)
            
              setTestTitle(json.title)}
            )

            await fetch(`/back-end/public/api/getUserTestTime/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else {
                            setMinutes(json - 1)
                            getCompareTime(json)
                        }
                    }
                )
    
               await fetch(`/back-end/public/api/getPublishedQuestion/${course_id}/${section_id}`).then(
            response => response.json()).then(
                json => {
                    if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                    else setQuestion(json)
                }
            )

            await fetch(`/back-end/public/api/getAnswer/${course_id}/${section_id}`).then(
                response => response.json()).then(
                    json => {
                        if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                        else setAnswer(json)
                    }
                )
                await fetch(`/back-end/public/api/getTotalScoreQuestion/${course_id}/${section_id}`).then(
                    response => response.json()).then(
                        json => {
                            if (json.msg === "not authorized") history.push({ pathname: '/not-found' })
                            else setTotalScore(json)
                        }
                    )
    
        }

       
      

        if (JSON.parse(localStorage.getItem(`${user.id}_user-answer`))) {
            setUser_Answer(JSON.parse(localStorage.getItem(`${user.id}_user-answer`)))
            setUserAnswers(JSON.parse(localStorage.getItem(`${user.id}_user-answer`)))
        }
    }

    useEffect(() => {

        if (user_answer.length > 0 && answers.length > 0) {
            var k = 0;
            for (var i = 0; i < answers.length; ++i) {
                for (var j = 0; j < user_answer.length; ++j) {
                    if (answers[i].id === user_answer[j].id) {
                        saveUserAnswers[k] = answers[i]
                        k = k + 1
                    }
                }

            }
        }

    }, [user_answer])

    async function submit() {

        setPercent(100)
        setOpen(true)

        const formData = new FormData();
        formData.append('course_id', course_id)
        formData.append('section_id', section_id)
        formData.append('answers', JSON.stringify(saveUserAnswers))

        if(section_id === '0')
        {
            await fetch("/back-end/public/api/addUserAnswersFinal", {
                method: "POST",
                body: formData
            }).then(() => {
               
                    history.replace({
                        pathname: `/home/corsi/${course_id}/controlla-I-risultati/${section_id}`,
    
                    })
               
                }
            )
        }
       else{
            await fetch("/back-end/public/api/addUserAnswers", {
            method: "POST",
            body: formData
        }).then(() => {
           
                history.replace({
                    pathname: `/home/corsi/${course_id}/controlla-I-risultati/${section_id}`,

                })
           
            }
        )
    }

        localStorage.removeItem(`${user.id}_user-answer`);

    }



    const handleNextPrev = (event) => {
        event.preventDefault();
        if (index < questions.length - 1 && next) {
            localStorage.setItem(`${user.id}_user-answer`, JSON.stringify(userAnswers));
            setIndex(index + 1)
            setNext(false)

            if (stepIndex < index || stepIndex === 0) {
                setStepIndex(index)

                setPercent(step + 100 / questions.length)
                setStep(step + 100 / questions.length)

            }

        }
        else if (index < questions.length && index > 0 && previous) {
            localStorage.setItem(`${user.id}_user-answer`, JSON.stringify(userAnswers));
            setIndex(index - 1)
            setPrevious(false)

        };
    }

    const handleChangeInput = (index, event, user_answer, score, correct_answer, question_id, answer_id, question_final_id) => {
        const values = [...userAnswers]
        const values_save = [...saveUserAnswers]
        const inde = values.findIndex((element, inde) => {
            if (element.id === answer_id) {
                return true
            }
        })

        const inde2 = values_save.findIndex((element, inde2) => {
            if (element.id === answer_id) {
                return true
            }
        })

        if (event.target.checked === true) {
            if (inde < 0) {
                setUserAnswers([...userAnswers, { id: answer_id, answers: user_answer }])

            }
            if (inde2 < 0) {
                if(section_id === '0')
                 setSaveUserAnswers([...saveUserAnswers, { answers: user_answer, score: score, correct_answer: correct_answer, question_id: question_final_id }])
                
                else setSaveUserAnswers([...saveUserAnswers, { answers: user_answer, score: score, correct_answer: correct_answer, question_id: question_id }])
            }
        }

        else {
            values.splice(inde, 1)
            values_save.splice(inde2, 1)
            setUserAnswers(values)
            setSaveUserAnswers(values_save)

        }

    }

    function reviewAndSubmit() {
        setReview(true)
        setPercent(100)
    }



    return (

        <div>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Paper style={{ textAlign: 'center', padding: '10px', marginTop: '10px' }}>

                        <Typography variant="h4">  {testTitle}</Typography>  
                        </Paper>
                        <Backdrop className={classes.backdrop} open={open}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Grid>


                    {questions.length > 0 && answers.length > 0 && review === false ?

                        <Grid item xs={12} lg={8}>
                            <Paper className={maxHeightPaper}>
                                <form onSubmit={handleNextPrev} >
                                    <Grid item lg={12} style={{ display: 'flex', padding: '10px' }}>
                                        <Typography variant="h6" className={classes.title} style={{ marginRight: '1em' }}>
                                            {index + 1}/{questions.length}
                                        </Typography>
                                        <Typography variant="h5" className={classes.title} >
                                            {questions[index].question}
                                        </Typography>
                                    </Grid>
                                    <Divider variant='fullWidth' /> 
                                    <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                                        {answers.filter(id => id.question_id === questions[index].id || id.question_final_id === questions[index].id)
                                            .map((item, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} lg={12} style={answerStyle}>
                                                        <Checkbox
                                                            checked={userAnswers.filter(ans => ans.id === item.id).toString()
                                                                ? true : false
                                                            }
                                                            disabled={open}
                                                            id="answer"
                                                            onChange={(event) => handleChangeInput(index, event, item.answers, item.score
                                                                , item.correct_answer, item.question_id, item.id, item.question_final_id)}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                        <Typography variant="h6" className={classes.title}>
                                                            {item.answers}
                                                        </Typography>
                                                    </Grid>
                                                )

                                            })}
                                    </Grid>
                                    {/* <FormHelperText id="my-helper-text">Choose answer</FormHelperText> */}

                                    <Grid item xs={12} lg={12} style={gridStyle}>


                                        {index !== 0 ?
                                            <Button onClick={() => { setPrevious(true) }} htmlType="submit"
                                                type="primary" className={classes.button} loading={open}
                                                style={{ color: '#fff', backgroundColor: '#4caf50', borderColor: '#4caf50' }}>
                                                Precedente
                                            </Button>
                                            : null
                                        }
                                        <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>


                                            {index === questions.length - 1 ?
                                                <Grid>
                                                    <Popconfirm onConfirm={() => submit()} title="Sei sicuro?" okText="Si" cancelText="No">
                                                        <Button htmlType="submit" loading={open}
                                                            type="primary" className={classes.button}
                                                            style={{ color: '#fff', backgroundColor: '#ff1744', borderColor: '#ff1744' }}>
                                                            invia
                                                        </Button>
                                                    </Popconfirm>
                                                    <Button onClick={reviewAndSubmit} htmlType="submit" loading={open}
                                                        type="primary" className={classes.button}
                                                        style={{ color: '#fff', backgroundColor: '#ff1744', borderColor: '#ff1744' }}>
                                                        Rivedi ed invia
                                                    </Button>
                                                </Grid>
                                                : null}
                                            {index !== questions.length - 1 ?
                                                <Button onClick={() => { setNext(true) }}
                                                    htmlType="submit" type="primary" loading={open}
                                                    className={classes.button} style={{ color: '#fff', backgroundColor: '#4caf50', borderColor: '#4caf50' }}>
                                                    Prossimo
                                                </Button>
                                                : null}
                                        </Grid>

                                    </Grid>
                                </form>
                            </Paper>
                        </Grid> : null
                    }

                    {questions.length > 0 && answers.length > 0 && review ?

                        <Grid item xs={12} sm={8} lg={8}>
                            {questions.map((item, ind) => (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <Paper className={maxHeightPaper}>
                                            <form onSubmit={handleNextPrev} >
                                                <Grid key={ind} item lg={12} style={{ display: 'flex', padding: '10px' }}>
                                                    <Typography variant="h6" className={classes.title} style={{ marginRight: '1em' }}>
                                                        {ind + 1}/{questions.length}
                                                    </Typography>
                                                    <Typography variant="h5" className={classes.title} >
                                                        {questions[ind].question}
                                                    </Typography>
                                                </Grid>
                                                <Divider variant='fullWidth' />
                                                <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                                                    {answers.filter(id => id.question_id === questions[ind].id || id.question_final_id === questions[ind].id)
                                                        .map((item, index) => {
                                                            return (
                                                                <Grid key={index} item xs={12} lg={12} style={answerStyle}>
                                                                    <Checkbox
                                                                        checked={userAnswers.filter(ans => ans.id === item.id).toString()
                                                                            ? true : false
                                                                        }
                                                                        id="answer"
                                                                        onChange={(event) => handleChangeInput(index, event, item.answers, item.score
                                                                            , item.correct_answer, item.question_id, item.id, item.question_final_id)}
                                                                        color="primary"
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                    //style={{ width: '12%', marginRight: '1rem' }}
                                                                    />
                                                                    <Typography variant="h6" className={classes.title}>
                                                                        {item.answers}
                                                                    </Typography>
                                                                </Grid>
                                                            )

                                                        })}
                                                </Grid>

                                            </form>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={12} lg={12} style={{ textAlign: 'end', marginTop: '2rem' }}>
                                <Popconfirm onConfirm={() => submit()} title="Sei sicuro?" okText="Si" cancelText="No">
                                    <Button htmlType="submit" loading={open}
                                        type="primary" className={classes.button}
                                        style={{ color: '#fff', backgroundColor: '#ff1744', borderColor: '#ff1744', width: '20%' }}>
                                        invia
                                    </Button>
                                </Popconfirm>
                            </Grid>
                        </Grid> : null
                    }



                    {questions.length > 0 && answers.length > 0 ?
                        <Grid item xs={12} sm={4} lg={4} >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Paper className={maxHeightPaper} style={{ textAlign: 'center' }}>

                                        <Grid item >
                                            <div style={{ backgroundColor: 'lawngreen' }}>
                                                <Typography variant="h6" >
                                                Tempo 
                                                </Typography>
                                            </div>
                                            <Typography variant="h6" style={{ padding: '8px 0 8px 0px' }}>
                                                {minutes < 10 ? 0 : null}{minutes}:{seconds < 10 ? 0 : null}{seconds}
                                            </Typography>
                                        </Grid>
                                        <Divider variant='fullWidth' />
                                        <Grid item style={{ padding: '8px 0 8px 0px' }} >
                                            <Typography variant="h6" >
                                            hai : {questions.length} Domande
                                            </Typography>


                                            <Typography variant="h6" >
                                            Punteggio totale : {total_score}
                                            </Typography>
                                        </Grid>

                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} style={{ textAlign: 'center' }}>
                                    <Paper style={{ padding: '20px' }}>
                                        <Progress type="circle" percent={Math.floor(percent)} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid> : null}
                </Grid>

            </Container>
        </div >

    );
}

export default withRouter(TakeTest);
