import "../App.css";
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withRouter, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../components/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { useStyles } from "../components/MainTheme.js";
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

function CourseDetails(props) {

    const { course_id } = useParams()

    const classes = useStyles();

    const [course, setCourse] = useState(null);


    useEffect(() => {
        getData()
    }, [])



    async function getData() {

        await fetch(`/back-end/public/api/getCourse/${course_id}`).then(
            response => response.json()).then(
                json => setCourse(json)
            )


    }

    return (
        <Container maxWidth="lg" >
            <Grid container spacing={3}>
                <Breadcrumb />

                <Grid item xs={12} sm={12} lg={9}>

                    <Grid item xs={12} sm={12} lg={12}>
                        <InputLabel htmlFor="age-native-helper">Descrizione del corso</InputLabel>
                    </Grid>
                    {course ? <Grid item xs={12} sm={12} lg={12}>
                        <Paper style={{ padding: '16px' }}>
                            <div dangerouslySetInnerHTML={{ __html: course.description }} >

                            </div>
                        </Paper>
                    </Grid> : null}
                </Grid>
                {course ?
                    <Grid item xs={12} sm={12} lg={3}>

                        <Card className={classes.cardHeight} style={{ marginTop: '1rem', height: 'fit-content' }}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={'/back-end/public/' + course.picture_path.replace('\\', "/")}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Corso
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {course.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </Grid> : null}
            </Grid>

        </Container>


    );
}

export default withRouter(CourseDetails);
