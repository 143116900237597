import React from 'react';
import { useStyles } from '../Routes/Layout/Layout.styles'
import clsx from "clsx";
import { Typography } from 'antd';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withRouter } from "react-router-dom"
import footer from './footer.png'; 

function Footer(props) {

    const classes = useStyles();

    return (

        <AppBar position="static" className={clsx(classes.appBar, {
            [classes.appBarShift]: props.open,
        })}>
            <Toolbar style={{ textAlign: 'center', display: 'grid', minHeight: '40px', backgroundColor: '#2999FB' }}>

                <Typography variant="h6" style={{ color: '#fff' }}>
                Copyright Alteredu.it made with <span style={{color: 'red', fontSize:'28px'}}>♥</span>
                <img alt='img' src={footer} style={{marginLeft: '5px', marginBottom: '10px', height: '22px'}}/>
                </Typography>
            </Toolbar>
        </AppBar>

    );
}

export default withRouter(Footer);