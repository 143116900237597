import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../components/MainTheme.js";
import Breadcrumb from "../components/Breadcrumb";
import Grid from '@material-ui/core/Grid';
import { Popconfirm, Button } from 'antd';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from '@mui/material';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';

function Courses() {
    const classes = useStyles();
    const history = useHistory();
    const maxHeightPaper = clsx(classes.paper, classes.maxHeight);
    const moment = require('moment');

    const [data, setData] = useState([]);
    const [coursePublished, setCoursePublished] = useState(false);

    
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    useEffect(() => {
        getData()
    }, [])



    async function getData() {

        await fetch("/back-end/public/api/courses", {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(
            response => response.json()).then(
                json => setData(json)
            )


    }

    async function deleteCourse(id) {
        await fetch("/back-end/public/api/delete/" + id, {
            method: "DELETE"
        }).then(() => getData())

    }

    function goToCourseDetails(item) {
        history.push({
            pathname: `/admin/corsi/${item.id}/dettagli-corso`
        })
    }

    function goToAddSections(item) {
        history.push({
            pathname: `/admin/corsi/${item.id}/sezioni`
        })
    }

    function goToEditCoure(item) {
        history.push({
            pathname: `/admin/corsi/${item.id}/aggiorna-corso`
        })
    }

    async function publishCourse($course_id)
    {
        const formData = new FormData();
        formData.append('course_id', $course_id);

        await fetch("/back-end/public/api/publishCourse", {
            method: "POST",
            body: formData
          }).then(
            response => response.json()).then(
              json => {
                if (json.msg === 'success') {
                    setCoursePublished(true)
                    getData()
                }
    
              })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCoursePublished(false);
    }

    return (

        <Container maxWidth="lg">
            <Grid container spacing={5} >
            <Snackbar open={coursePublished} autoHideDuration={3000} onClose={handleClose} >
                                <Alert onClose={handleClose} severity="success">
                                Corso pubblicato
                                </Alert>
                            </Snackbar>
                <Grid item xs={8} sm={12} lg={12}>
                    <Breadcrumb />
                    <Paper className={maxHeightPaper} style={{marginTop: '1rem'}}>
                        <TableContainer >
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Corso</TableCell>
                                        <TableCell >Nome del Corso</TableCell>
                                        <TableCell >Tipologia</TableCell>
                                        <TableCell >Data Creazione</TableCell>
                                        <TableCell>Visualizza e Modifica</TableCell>
                                        <TableCell >Descrizione Corso</TableCell>
                                        <TableCell >Pubblica corso</TableCell>
                                       <TableCell>Elimina</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell >{index + 1}
                                            </TableCell>
                                            <TableCell >

                                             <Link  onClick={() => goToAddSections(item)}>  {item.title}</Link>
                                            </TableCell>
                                            <TableCell>
                                                {item.type === 'Normal' ? 'Corsi sola visualizzazione' : 'Formazione Obbligatoria (conTimer)'}
                                            </TableCell>
                                            <TableCell>
                                                {moment(item.created_at).format('DD/MM/YYYY, h:mm:ss a')}
                                            </TableCell>
                                            <TableCell>
                                                <Button onClick={() => goToEditCoure(item)} icon={<EditOutlined style={{ fontSize: "20px" }} />} />
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <Button onClick={() => goToCourseDetails(item)} icon={<EyeOutlined style={{ fontSize: "20px" }} />} />
                                            </TableCell>
  
                                            <TableCell>
                                            {item.published === 0 ?
                                                 <Popconfirm
                                                    onConfirm={() => publishCourse(item.id)}
                                                    title="Sei sicuro?" okText="Si" cancelText="No">
                                                    <Button type="link"  style={{padding: "0px"}}>Pubblica  </Button>
                                                </Popconfirm> : "Pubblicato"}
                                            </TableCell>
                                            <TableCell>
                                                <Popconfirm
                                                    onConfirm={() => deleteCourse(item.id)}
                                                    title="Sei sicuro?" okText="Si" cancelText="No">
                                                    <Button icon={<DeleteOutlined style={{ fontSize: "18px" }} />} />
                                                </Popconfirm>
                                            </TableCell>
                                        </TableRow>

                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Courses;
