import React, { useState, useEffect } from "react";
import { withRouter, Link, useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../components/MainTheme.js";
import Breadcrumb from "../components/Breadcrumb";
import Grid from "@material-ui/core/Grid";
import MuiAlert from '@material-ui/lab/Alert';


function StudentCourseEnrolled(props) {
    const classes = useStyles();

    const { user_id } = useParams()

    const [courses, setCourses] = useState([]);
    const [studentInfo, setStudentInfo] = useState(null);
    const [noCourse, setNoCourses] = useState(false);


    function Alert(props) {
        return <MuiAlert elevation={2} variant="outlined" {...props} />;
    }
    useEffect(() => {
        getData()
    }, [])


    async function getData() {
        await fetch(`/back-end/public/api/getStudentcourseForAdmin/${user_id}`).then(
            response => response.json()).then(
                (json) => {
                    if (json.length > 0) setCourses(json)
                    else setNoCourses(true)
                }
            )

        await fetch(`/back-end/public/api/getUserProfileAdmin/${user_id}`).then(
            response => response.json()).then(
                (json) => setStudentInfo(json[0])
            )

    }
    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} justify='flex-start'>
                <Breadcrumb />

                <Grid item xs={12} lg={9}>
                    {courses.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell >#</TableCell>
                                        <TableCell >Nome del Corso</TableCell>
                                        <TableCell >Tipologia</TableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {courses.map((item, index) => (
                                        <TableRow key={index} >
                                            <TableCell > {index + 1}
                                            </TableCell>
                                            <TableCell>
                                                <Link to={{
                                                    pathname: `/admin/studenti-iscritti/${user_id}/corsi/${item.id}/sezioni`,

                                                }}
                                                >{item.title}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {item.type === 'Normal' ? 'Corsi sola visualizzazione' : 'Formazione Obbligatoria (con Timer)'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : null
                    }
                    {noCourse ? <Alert severity="info">Non sei registrato in nessun corso!</Alert> : null}
                </Grid>
                {studentInfo ?
                    <Grid item xs={12} lg={3}>
                        <Paper style={{ textAlign: 'center' }}  >
                            <Grid item xs={12} style={{ background: 'lightgreen' }}>
                                <Typography variant='h6'> Nome Corsista </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <Typography>  {studentInfo.name} {studentInfo.surname}</Typography>
                            </Grid>
                        </Paper>
                    </Grid> : null}

            </Grid>
        </Container>

    );
}

export default withRouter(StudentCourseEnrolled);