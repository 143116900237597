import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React, { useEffect, useState, useContext } from "react";
import { useHistory ,useLocation } from "react-router-dom";
import { useStyles } from "./Layout.styles";
import menuItems from "./menuItems";
import Grid from '@material-ui/core/Grid';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Footer from "../../components/Footer";
import Context from "../../Context"
import { LoginOutlined, SettingOutlined , UserOutlined  } from '@ant-design/icons';
import alteredu from './alteredu.png'; 
import footer from './footer.png'; 

export default function Layout({ Componentx: Component, propsx }) {

  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const matches_mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const matches_desktop = useMediaQuery(theme.breakpoints.up('sm'))


  const { user, setUser } = useContext(Context)
  const { setAuth } = useContext(Context)

  const [anchorEl, setAnchorEl] = useState(null);
  const openIcon = Boolean(anchorEl);

  const location = useLocation();

  async function logOut() {
    handleClose()
    setOpen(false);
    await fetch("/back-end/public/api/logout", {
      method: "POST"

    }).then(
      response => {
        if (response.ok) {
          setUser(null)
          setAuth(false)
     
          history.replace("/entra")
        }
      })
  }




  const [open, setOpen] = useState(false);
  const [openSwipeable, setOpenSwipeable] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (matches_mobile)
      setOpen(false)
    if (matches_desktop)
      setOpenSwipeable(false)
  }, [matches_mobile, matches_desktop])


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function UserProfile() {
    handleClose()
    history.push('/home/profilo')
  }

  function AdminProfile() {
    handleClose()
    history.push('/admin/settings')
  }

  return (
    <div className={classes.root}>
      <div style={{ flex: '1' }}>
        <div className={classes.wrap}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            style={{ backgroundColor: '#2999FB' }}
          >
            <Toolbar variant="dense" style={{ justifyContent: 'center' }}>
              {user ?
                <>
                  {user.user_type === 'ADM' ?
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                      })}
                    >
                      <MenuIcon />
                    </IconButton> : null}

                  {user.user_type === 'ADM' ?
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => setOpenSwipeable(true)}
                      edge="start"
                      className={classes.menuButton2}
                    >
                      <MenuIcon />
                    </IconButton> : null}

                    {user.user_type === 'USR' && !location.pathname.includes('take-test') ? <a href="/home" style={{color:"#fff"}}>
                    <img alt='img' src={alteredu} style={{ height: '40px'}}/> </a> : null}


                  <Grid item xs={12} lg={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {user ?
                      <Typography noWrap style={{ padding: '6px' }}> {user.first_name} {user.last_name}
                      </Typography> : null}
                   {user && !location.pathname.includes('take-test') ? 
                  <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit" >
                  <AccountCircle />
                </IconButton> 
                : null}

                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                      transformOrigin={{ vertical: "top", horizontal: "center" }}
                      open={openIcon}
                      onClose={handleClose}
                    >
                      {user.user_type === 'USR' && user.email_verified === 1 ? <MenuItem onClick={() => UserProfile()}>
                        <UserOutlined style={{  marginRight: '10px' }}/>  Profilo</MenuItem> : null}
                        {user.user_type === 'ADM' && user.email_verified === 1 ? <MenuItem onClick={() => AdminProfile()}>
                        <SettingOutlined style={{  marginRight: '10px' }}/>  Impostazioni</MenuItem> : null}
                      <Divider />
                      <MenuItem onClick={logOut}>
            <LoginOutlined  style={{  marginRight: '10px' }} />Esci</MenuItem>
                     
                    </Menu>
                  </Grid>
                </>
                :
                <Typography component="h2" variant="h6" style={{ color: '#fff' }}>
                  <img alt='img' src={footer} style={{ height: '40px', marginBottom: '5px'}}/>
                </Typography>
              }
            </Toolbar>
          </AppBar>
          {user && user.user_type === 'ADM' ?
            <SwipeableDrawer
              anchor='left'
              open={openSwipeable}
              onClose={() => setOpenSwipeable(false)}
              onOpen={() => setOpenSwipeable(true)}
            >
              <div className={clsx(classes.list)}>
                <List>
                  {menuItems.filter(item => item.type === user.user_type).map(({ name, route, icon }, index) => (
                    <ListItem
                      onClick={() => history.push(route)}
                      button
                      key={`menuitem-${index}`} >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
                <Divider />
              </div>
            </SwipeableDrawer>
            : null
          }
          {user && user.user_type === 'ADM' ?
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                {open ?
                  <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton> :
                  <IconButton onClick={handleDrawerOpen}>
                    <ChevronRightIcon />

                  </IconButton>}
              </div>
              <Divider />
              <List>
                {menuItems.filter(item => item.type === user.user_type).map(({ name, route, icon }, index) => (
                  <ListItem
                    selected={propsx.location.pathname.includes(route) ? true : false}
                    onClick={() => history.push(route)}
                    button
                    key={`menuitem-${index}`}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                ))}
              </List>
              <Divider />
            </Drawer> : null}
          {/* <main className={classes.content}> */}
          <main style={{ width: '100%', marginTop: '3rem', marginBottom: '1rem', minHeight: '100vh' }}>
            <div className={classes.toolbar} />
            <div className="content">
              <Component {...propsx} />
            </div>
          </main>
        </div>
      </div>
      <Footer open={open} />
    </div>
  );
}
